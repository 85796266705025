
$( function () {
  $('#partner-users-table').DataTable(
    {
      "ajax": {
        "url": $(this).data('source'),
      },
      "columns": [
        { "mData": "first_name", className: "dt-left"  },
        { "mData": "last_name", className: "dt-left"  },
        { "mData": "email", className: "dt-left"  },
        { "mData": "nr_affiliates", className: "dt-left"  },
        ],
      "order": [[ 1, "asc" ]],
      "responsive": true,
      "processing": true,
      "language":
        {
          "url": '/ajax/data_table_strings?l=de'
        },
      "serverSide": true,
    }
  );
} );
