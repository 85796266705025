$( function () {
  $('#commission-table').DataTable(
    {
      "responsive": true,
      "language":
        {
          "url": '/ajax/data_table_strings?l=de'
        },
    }
  );
} );
