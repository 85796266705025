import clipboard from 'clipboard';
$( function () {
  const lang = $("body").data("lang");
  $("a.locale_trigger").bind("ajax:success", function(evt) {
    const [_data, _status, xhr] = event.detail;
    console.log(_data);
    let url = new URL(window.location.href);
    url.searchParams.set('l', _data.locale);
    window.location.href = url.href;
  });
  var cols = [
        { "mData": "company", className: "dt-left"  },
        { "mData": "first_name", className: "dt-left"  },
        { "mData": "last_name", className: "dt-left", responsivePriority: 1  },
        { "mData": "email", className: "dt-left"  },
        { "mData": "nr_users", className: "dt-left", orderable: false  },
        ]

  if ( $("#user_col_header").length ) {
      cols.push(
        { "mData": "partner_user", className: "dt-left"  },
      )
  }
  new clipboard('#copy-reg-link-btn');
  $('#affiliates-table').DataTable(
    {
      "ajax": {
        "url": $(this).data('source'),
      },
      "columns": cols,
      "order": [[ 1, "asc" ]],
      "responsive": true,
      "language":
        {
          "url": '/ajax/data_table_strings?l=' + lang
        },
      "serverSide": true,
    }
  );


  $('#contracts-table').DataTable(
    {
      "ajax": {
        "url": $(this).data('source'),
      },
      "columns": [
        { "mData": "date", className: "dt-left"  },
        { "mData": "end_date", className: "dt-left"  },
        { "mData": "customer", className: "dt-left"  },
        { "mData": "objects", className: "dt-left"  },
        { "mData": "insured_sum", className: "dt-left"  },
        { "mData": "status", className: "dt-left", orderable: false  },
        { "mData": "actions", className: "dt-right", orderable: false  },
        ],
      "order": [[ 0, "desc" ]],
      "responsive": true,
      "language":
        {
          "url": '/ajax/data_table_strings?l=' + lang
        },
      "serverSide": true,
    }
  );

  $('#voucher_code_table').DataTable(
    {
      "order": [[ 0, "asc" ]],
      "responsive": true,
      "language":
        {
          "url": '/ajax/data_table_strings?l=' + lang
        },
    }
  );

} );

